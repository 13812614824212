@import '../../../theme.scss';
.header-collectionOrder{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    border:1px solid  $primaryColorTheme;
    background-color: $primaryColorTheme;
    z-index: 810;
    width:100%;
    height: 53px;

    .header-collectionOrder-logo{
        position:relative;
        width: 300px;
        height: 53px;
        background-image: url('../../../assets/images/logo/logo-reverse.png');
        background-size: 200px 45px;
        background-repeat: no-repeat;
        margin: 5px 18px;
    }
}

.header-title{
    margin-top: 10px;
    margin-left: 15px;

    .lxSplitter{
        width: 99%;
    }
}

.collectionOrder-loadDanger{
    width: 100%;
    height: 50px;
    padding: 5px;
    background-color: $secondaryColorTheme;
    border-radius: 4px;
    // opacity: 0.5;
}
.collectionOrder-loadDanger h1{
    color: white;
    margin-left: 10px;
}

.collectionOrder-success{
    width: 100%;
    height: 50px;
    padding: 5px;
    background-color: $primaryColorTheme;
    border-radius: 4px;
    // opacity: 0.5;
}

.collectionOrder-success h1{
    color: white;
    margin-left: 10px;
}
.message-erro{
    color: red;
}