@import '../../theme.scss';

.text-selector{
    display: flex;
    align-items:flex-end;
    line-height: 1.3;
    margin-bottom: 5px;
    &__img{
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
    &__text{
        padding-left: 5px;
        color: $secondaryColorTheme;
        font-family: 'Rajdhani', sans-serif;
        font-weight: bold;
        font-size : 16px;
        text-transform: capitalize;
        cursor: pointer;
    }
}
