.map-view-route{
     width: 100%;
    height: 100%;
    overflow: auto;
     
    .lxButtonDialogHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center; 
        background-color: rgba(255, 255, 255, 0.7); 
        position: absolute;
        z-index: 999;
        top: 10px;
        left: 10px;
        bottom: 10px;
        right: 10px;
    }
    
    .spinner {
        border: 5px solid rgba(255, 255, 255 ,0.7);
        border-radius: 50%;
        border-top: 5px solid #0065a9;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }
    .empty-route{
        margin: auto;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}