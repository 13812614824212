.content-label-upload{
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    .icon-upload{
        margin-top: 6px;
        z-index: 2;
        background-color: white;
    }
    .content-center{
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .lxUploadFileButton{
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0px;
        left: 0;
        bottom: 0;
        z-index: -2;
    }
    .img-preview{
        max-height: 100%;
        max-width: 90%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}