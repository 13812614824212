.travel-view-map {
  position: relative;
  .content-travel-detail {
    margin-top: 20px;

    .travel-content-detail {
      border: 1px solid #dddddd;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 15px;
      background-color: #ececec;

      .line {
        height: 10px;
        width: 50%;
        background-color: #769989;
        border: 1px solid #ccc;
        border-radius: 10px;
        position: relative;
      }
      .travel-detail {
        display: flex;
        align-items: center;
      }
    }
    .timeline {
      width: 100%;
      padding-top: 3px;
      min-height: 36px;
    }

    .percent-line {
      width: fit-content;
      color: #f100ff;
      position: absolute;
      top: -20px;
      right: 5px;
    }
  }

  .line-foreground {
    width: 100%;
    background-color: #f5fffc;
    border-radius: 10px;
    padding: 1px;
    position: relative;
    height: fit-content;
  }

  .image-veiculo-icon-timeline {
    width: auto;
    height: 29px;
    position: absolute;
    right: 2px;
    top: 6px;
  }
  .icon-pin-timeline {
    width: auto;
    height: 23px;
    position: absolute;
  }

  .content-pin-deliverys {
    width: 100%;
    position: relative;
    height: 20px;
  }
  .end-pin {
    position: absolute;
    right: 10px;
  }
  .content-delivery-location {
    display: flex;
    margin-top: 5px;
  }

  .content-origin-end {
    background-color: #777c7c;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    border: 5px solid;
    border-color: #939190;
  }
  .content-obs {
    background-color: white;
    border: 1px solid #ccc;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .obs-title {
    font-weight: 800;
  }
  .obs-title-span {
    font-weight: bold;
  }

  .btn-exit-mapa {
    position: absolute;
    right: -15px;
    top: -15px;
  }
  .header-mapa-travel {
    display: flex;
    justify-content: space-between;
  }
}
