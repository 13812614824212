.cerca-virtual{
    position: relative;
    
    #mapCercaVirtual{
        height: 80vh;
        width: 100%;
         padding: 0;
        z-index: 0;
        margin: 0;
        position: relative;
    }
    .content-lateral-dir{
        display: flex; 
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 600px;           
        background-color: white;         
        border-radius: 5px;
        max-height: 600px;
        overflow: auto;
    }
    .content-btn-cerca-virtual{
        
        margin: auto;
        margin: 0;
        padding: 0;
    }
}