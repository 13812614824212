@import '../../theme.scss';

.lxTimeline{
    margin: auto auto;
    width: 60%;
    height: auto;
    position:relative;
    text-align:center;
    &:after {
        content:"";
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 50%;
        border-left: 2px dotted $primaryColorTheme;
        transform: translate(-50%);
    }
    &__milestone{
        display: flex;
        & > .circle {
            position : absolute;
            margin-left: 49.5%;
            margin-top: 0.75%;
            background: $primaryColorTheme;
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }
    }
}

