@import '../../../theme.scss';
.lxDashboardNfe{
  display: flex;
  .p-grid{
    width: 100%;
  }
  .content-lines-inf{
    display: flex;
  }
  .line-dash-inf-nfe{
    flex-wrap: wrap;
    margin-right: 5px;
    p{
      // padding-left: 5px;      
    } 
    input{
      width: 135px;
    }
    .line-dash-inf-nfe-item{
      background-color: #F0F8FB;
      color: #16396599; 
      min-width: 80px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      height: 63%;
    }
  }
  
  .line-dash-titulo{
    margin-bottom: 5px;
    align-self: center;
    box-sizing: border-box;
    color: #FF3303;
    font-size: 19px;
  }
  .content-cads-nfe{
    display: flex;
    flex-wrap: wrap;
    margin-top: 7px;
  }
  // cards

  .grid-box{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 234px;
    height:145px;
    overflow: hidden;
    background-color: #F0F8FB;
    font-weight: bold;
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    border-radius:10px;
    margin: 10px;
    padding: 5px;
    font-family: $fontFamily;

    &__title{
        // padding-left: 25px;
        margin-bottom: 5px;
        align-self: center;
        box-sizing: border-box;
        color: $secondaryColorTheme;
        font-size: 19px;
        
    }
  }


  .content-card{
    box-sizing: border-box;
    margin:0;
    padding: 0px 0px 0px 0px; 
    font-size: 24px;
    line-height: 1;
    color: #16396599; 
  }

  .card-footer{
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 15px 25px 0px 25px;

  }
  .map-dash-nfe{
    background-color: #F0F8FB;
    z-index: 1;
    font-weight: bold;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    margin-top: 14px;

  }
  .grid-content-filtro{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1px;
  }
    .loading-content {
      position: absolute;
      z-index: 9999  !important;
      top: 0px;
      bottom: 0px;
      background: #d7d0d038 !important;
      right: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
  }
} 