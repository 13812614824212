@import '../../theme.scss';

.modal-date {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 145px;
    overflow: hidden;
    font-weight: bold;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;

    .data {
        display: flex;
        color: $primaryColorTheme;
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 10px;
        justify-content: center;
    }
    .switch-align-vertical-center {
        margin-top: 6px;
        flex-direction: row;
        display: flex;
    }
}

.content {
    box-sizing: border-box;
    margin: 0;
    padding: 0px 0px 0px 0px;
    margin-top: 5px;
}

.footer {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding: 15px 25px 0px 25px;
}

.content-occurrences-type {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}