.lookup-field-complete{
    .p-autocomplete-input{
        width:100%;
    }
}

.p-dialog-footer {
    & button{
        padding: 8px;
    }
}

