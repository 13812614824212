@import '../../theme.scss';

.lognex-sidebar{
    display: flex;
    flex-direction: row;
    margin:0;
    padding:0;
    width: 200px;
    min-height: 100vh;
    transition: all 0.8s ease-in-out 0.3s;
        
    .sidebar-button{
        width: 20px;
        height: 20px;
        margin-left: 4px;
        margin-top: 4px;
        background: url("../../assets/images/sidebar/bars_16x16_reverse.png") no-repeat center;
        background-size: 16px;
        transition: all 0.8s ease-in-out 0.3s;
        & i {
            color : white;
        }
    }

    &.closed{
        width: 60px;
        .lognex-brand{
            width:60px;
            .lognex-brand__logo{
                width:0;
            }
        }
        
        & .lognex-sidemenu__menuItemLabelParent > :last-child{
            opacity: 0;
	        width: 0;
            overflow: visible;
        }
        & .lognex-sidemenu__menuItemLabelParent{
            width: 60px;
            overflow: hidden;
        }

        & .sidebar-button{
            background: url("../../assets/images/sidebar/logo_x_reverse.png") no-repeat center ;
            background-size: 24px;
        }
    } 

    .lognex-brand{
        z-index: 10;
        background-color: $primaryColorTheme;
        display: flex;
        height: 53px;
        align-items: flex-start;
        position: fixed;
        padding-top: 13px;
        padding-bottom: 13px;
        padding-left: 15px;
        width: 200px;
        min-width: 200px;
        top: 0;
        border: 1px solid $primaryColorTheme;
        overflow: hidden;
        transition: all 0.8s ease-in-out 0.3s;
        background-color: $primaryColorTheme;
        z-index: 820;

        .lognex-brand__logo {
            transition: all 0.8s ease-in-out 0.3s;
            margin-left:10px;
            width: 120px;
            height: 25px;
            overflow: hidden;
            
            & > img {
                width: 120px;
            }
        }
                    
    }

    .content{
        margin-top: 55px;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0px;
        transition: all 0.8s ease-in-out 0.3s;
        z-index: 810;
    }

    

}