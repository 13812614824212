.travel-form{
    .form-field-container{
        display: flex;
        flex-direction: column;
    }
    .form-group{display: inline-block; }
    .input-route{
        max-width: 150px;
    }
    .space-separator{
        margin-left: 8px;
        margin-right: 8px;
        display: initial;
    }
    .row-buttons{
        flex-direction: row;
    }
    .bottons{
        margin-right: 8px !important;
        cursor: pointer;
    }

    .p-card-content{
        padding-top: 5px;
    margin-top: 8px;
}
    
}