.lxCargoShipmentCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f0f8fb;
  margin: 5px auto;
  font-size: 22px;
  text-align: center;
  padding: 10px;
  -webkit-box-shadow: 5px 3px 2px rgba(170, 197, 207, 0.50);
  -moz-box-shadow: 5px 3px 2px rgba(170, 197, 207, 0.50);
  box-shadow: 5px 3px 2px rgba(170, 197, 207, 0.50);
  margin-bottom: 10px;

  h3{
    padding:15px;
  }

  p {
    color: #ff3303;
    font-size: 16px;
    font-family: "Rajdhani",sans-serif;
    font-weight: 700;
  }

  
} 
.rotate-image-carg {
  margin-top: 10px;
  width:500px;
  height:500px;
  transform: rotate(90deg) translateY(-100%);
  transform-origin:top left;
  padding: 10px 10px 10px 10px;
  border: 1px solid #BFBFBF;
  background-color: white;
}

.spin {
  margin-top: 10px;
  width:500px;
  height:500px;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin:top left;
  padding: 10px 10px 10px 10px;
  border: 1px solid #BFBFBF;
  background-color: white;
}

.spin180-carg {
  margin-top: 10px;
  width:500px;
  height:500px;
  transform: rotate(90deg) translateY(-100%);
  transform-origin:top left;
  padding: 10px 10px 10px 10px;
  border: 1px solid #BFBFBF;
  background-color: white;
}

.spin360-carg {
  margin-top: 10px;
  width:500px;
  height:500px;
  transform: rotate(-360deg);
  transform-origin:button right;
  padding: 10px 10px 10px 10px;
  border: 1px solid #BFBFBF;
  background-color: white;
} 
.btnCalcFreightQuote{
  border: 1px solid red;
  float: right;
  top: 10px;
}

.lxLookupDialogFreightQuote{
  width: 90%;
  // height: 70vh;
}


