.pendencia-ocorren{
    color: red;
    font-weight: 700;
}
.no-delivered{
    color: red;
    font-weight: 700;
}
.delivered{
    color: rgb(8, 134, 107);
    font-weight: 400;
}
.no-pendencia-ocorren{ 
    // font-weight: 700;
}
.p-grid-filter{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 10px;

    .field-filter{
        // margin-right: 10px;
        margin-left: 15px;
    }
    .btl-filtrar{
        width: 120px;
    }
}