@import "../../../theme.scss";

.p-calendar {
  .p-inputtext {
          width: 100%;
  }
  .p-datepicker {
          min-width: 300px !important;
  }
}
.p-datatable-resizable>.p-datatable-wrapper {
  overflow: visible !important;
}
.p-resizable-column{
  overflow: visible !important;
}

.lognex-btn-highlighted{
  cursor: pointer; 
}
.row-content{
  border-bottom: 1px solid #858585;
}
.p-dialog-title{
  float: left;
}