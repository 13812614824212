.dock-manager-form {
  display: flex;
  width: 98%;
  height: 90vh;
  background-color: #e5e5e5;
  position: absolute;
  margin: 15px;
  .container-fixed-lateral {
    height: 100%;
    width: 340px;
    overflow-x: hidden;
    background: #f7f7f7;
    display: flex;
    overflow-y: auto;
    min-width: 340px;
    z-index: 15;
  }

  .col-hour {
    display: flex;
    align-items: flex-end;
    position: relative;
    height: 80px;
    flex-direction: column;
    justify-content: flex-start;
    width: 40px;
    left: 0;
    position: sticky;
    
  }
  .label_hour{
    font-weight: 100; 
    color: #a3a3a3;
    font-size: small;
  }
  .label_destaque{
    font-weight: 600;    
    color: #464646;
    font-size: medium;
  }
  .content-docas { 
    height: 100%;
    padding-top: 30px;
    margin-left: 40px;

    .cell-agenda {
      background-color: white;
      border: 0.1px solid;
      cursor: pointer;
      border-color: #e7e7e7a1;
    }
    .cell-agenda:hover {
      border-color: #db9393;
    }
    .row {
      display: flex;
      flex-direction: row;
    }
    .cell-height {
      height: 80px;
    }
    .invalid-horario{
      background-color: #e5e5e5 !important;
      border:none !important;
      cursor: default; 
     }
      
  }
  .cell-width {
    width: 250px;
  }
.border-content-empty{
  border: 0.5px solid #d8d8d8;
}
  .container-headers {
    position: absolute;
    top: 0px;
    z-index: 15;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: nowrap;
    font-weight: 700;
    // background-color: #ffffff;
  }
  .col-docks {
    text-align: center;
    border-left: 1px solid #ffffff;
    /* border-right: 1px solid #005be3; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #abd5ff;
  }

  .content-docas-hours {
    padding-top: 22px;
    position: absolute;
    background-color: #ffe5e5;
    z-index: 15;//ficar acima da header
  }
  .content-docas-agend {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
  }
  .content-docas-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    width: fit-content;
  }
   

  .panel-sob-inactive{  
    opacity: 0.4;
    background-color: #efe3e3 !important;
    p{
      color: rgb(169, 173, 184);
      font-weight: 400;
      font-size: 12px;
    }
    span{
      font-weight: 600;
      color: rgb(124, 130, 145);
      font-size: 14px;
    }
  }
   
  .panel-sob{
    background-color: white;
    overflow: hidden;
    text-align: left;
    padding: 5px;
    position: absolute;
    z-index: 10;
    box-shadow: 2px 7px 12px 0px #bfbfbf;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    justify-content: flex-start;
    width: 248px;
    font-family: 'Open Sans';
    p{
      color: rgb(89, 97, 117);
      font-weight: 400;
      font-size: 12px;
    }
    span{
      font-weight: 600;
      color: rgb(32, 78, 204);
      font-size: 10px;
    }
    h3{
      color: rgb(32, 78, 204);
    }
  }
  .panel-sob:hover{
    box-shadow: 2px 7px 12px 0px #838383;
    z-index: 11;
    // background-color: #cf6b6b;
  }

  .panel-sob-no-patio{
    background-color: #ffe35a;
  }
  .panel-sob-atrasado{
    background-color:#f98c8c;
  }
  .panel-sob-carregado{
    background-color:#84f0a1;
  }
  .panel-sob-veiculo-vazio{
    background-color:#3478b5;
    p{
      color: rgb(255, 255, 255);
      font-weight: 400;
      font-size: 12px;
    }
    span{
      font-weight: 600;
      color: rgb(255, 255, 255);
      font-size: 10px;
    }
    h3{
      color: rgb(255, 255, 255);
    }
  }
  .panel-sob-sem-operacao{
    background-color:#676767;
    p{
      color: rgb(255, 255, 255);
      font-weight: 400;
      font-size: 12px;
    }
    span{
      font-weight: 600;
      color: rgb(255, 255, 255);
      font-size: 10px;
    }
    h3{
      color: rgb(255, 255, 255);
    }
  }
  

  .tooltip-info{
    font-weight: 600;
      color: white !important; 
      font-size: 14px;
  }
  .info-schedule{
    height: 100%;
    margin-right: 10px;
  }
  .btn-remove-schedule{
    position: absolute;
    right: 0px;
    top: 0px;
    width: 30px;
    height: 25px; 
    text-align: end; 
    cursor: pointer;
  }
  .btn-remove-schedule-icon{
    color: white;
    font-size: 23px;
  }
  .btn-remove-schedule-icon:hover{
    opacity: 0.6;
    font-size: 26px;
  }
  .dock-disabled{
    background-color: #f1f1f1 !important; 
    cursor: default  !important;
  }
}
