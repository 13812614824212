.card-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin: 17px;
  flex-direction: row;
  align-items: stretch;
}

.card-expanded {
  padding: 20px;

  background-color: #d1e3eb;
  h3{
    margin-left: 20px;
  }
}

.card {
  width: 300px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
