@import "../../../theme.scss";

.auditrecord_table{

  .lxExclamationMark{
    color : $secondaryColorTheme;
    vertical-align: -10%;
  }

  .lxNumberAudit{
      text-align: center;

  }

  .quoted_value{
  text-align: center; 
  }

  .newauditinconsistency{
    &_open{
        font-weight: bold;
        color: $lightGreyColor;
        background-color: $failureColor;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &_solved{
        font-weight: bold;
        background-color: #458B00;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }  


  .rotate-image {
    margin-top: 10px;
    width:500px;
    height:500px;
    transform: rotate(90deg) translateY(-100%);
    transform-origin:top left;
    padding: 10px 10px 10px 10px;
    border: 1px solid #BFBFBF;
    background-color: white;
  }

  .spin {
    margin-top: 10px;
    width:500px;
    height:500px;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin:top left;
    padding: 10px 10px 10px 10px;
    border: 1px solid #BFBFBF;
    background-color: white;
  }

  .spin180 {
    margin-top: 10px;
    width:500px;
    height:500px;
    transform: rotate(90deg) translateY(-100%);
    transform-origin:top left;
    padding: 10px 10px 10px 10px;
    border: 1px solid #BFBFBF;
    background-color: white;
  }

  .spin360 {
    margin-top: 10px;
    width:500px;
    height:500px;
    transform: rotate(-360deg);
    transform-origin:button right;
    padding: 10px 10px 10px 10px;
    border: 1px solid #BFBFBF;
    background-color: white;
  }

  .lxTableNewAudit{
    & button{
      margin-top: 10px;
      width: 100px;
    }
  }

  .totalizator-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
  }
}



