.lxSocialMediaBar{
    transition: all 0.8s ease-in-out 0.3s;
    align-self: center;
    display: none;
    padding: 10px;
    & > * {
        width: 32px;
        height: 32px;
        margin : 2px;
    }
    &__facebook{
    }

    &__instagram{
        
    }

    &__linkedin{
        
    }
}

.closed .lxSocialMediaBar{
    align-content: center
}