.totalizers_tower {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding-bottom: 8px;

  .setting_tower_cards_totalizers {
    width: 25px;
    height: 86px;
    cursor: pointer;
  }
  .iconTotalizer {
    transform: rotate(90deg);
  }
  .tower_totalizadores {
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .card_tower_totalizador {
      display: block;
      width: 100%;
      max-width: 199px;
      min-width: 153px;
      margin-right: 10px;
      margin-top: 10px;
    }
    .grid-box {
      height: 80px;
      width: 100%;
      cursor: pointer;
      transition: transform 0.2s;
      margin: 2px;
    }
    .grid-box:hover {
      transform: scale(1.05);
    }
    .grid-box-selected {
      height: 80px;
      width: 100%;
      cursor: pointer;
      transition: transform 0.2s;
      margin: 2px;
    }
    .grid-box-selected:hover {
      transform: scale(1.05);
    }
    .p-component {
      font-size: 12px;
    }
    /*.travel-map{
    display: flex !important;   
    flex-wrap: wrap;
    flex-direction: row; 
  }*/
  }
  .tower_totalizadores-small {
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .card_tower_totalizador {
      display: block;
      width: 100%;
      max-width: 144px;
      min-width: 125px;
    }
    .grid-box {
      height: 64px;
      width: 100%;
      cursor: pointer;
      transition: transform 0.2s;
      margin: 2px;
      .content {
        box-sizing: border-box;
        margin: 0;
        padding: 0px 0px 0px 0px;
        margin-top: 5px;
        font-size: 32px;
        line-height: 1;
        color: #16396599;
      }
      .title {
        margin-bottom: 5px;
        align-self: center;
        box-sizing: border-box;
        color: #ff3303;
        font-size: 10px;
      }
    }
    .grid-box:hover {
      transform: scale(1.05);
    }
    .grid-box-selected {
      height: 64px;
      width: 100%;
      cursor: pointer;
      transition: transform 0.2s;
      margin: 2px;

      .content {
        box-sizing: border-box;
        margin: 0;
        padding: 0px 0px 0px 0px;
        font-size: 32px;
        line-height: 1;
        color: #f0f8fb;
      }
      .title {
        align-self: center;
        box-sizing: border-box;
        color: #f0f8fb;
        font-size: 19px;
      }
    }
    .grid-box-selected:hover {
      transform: scale(1.05);
    }
    .p-component {
      font-size: 10px;
    }
    /*.travel-map{
    display: flex !important;   
    flex-wrap: wrap;
    flex-direction: row; 
  }*/
  }
  .setting_tower_cards_totalizers_dialog {
    min-width: 150px;
    max-width: 250px;
    .p-dialog-titlebar {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
    .content {
    }
  }
  .inputCheckTotalizer {
    padding-left: 10px;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

    &:hover {
      background-color: #ccc;
    }

    label {
      color: red;
      margin-left: 5px;
      pointer-events: none;
    }
    input {
      pointer-events: none;
    }
  }
}
