.loading-content {
  position: absolute;
  top: 0px;
  bottom: 0px; 
  background: #0000008f;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  .loader-center {
    background-color: white;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
  }
}
.mensg-content {
  position: absolute;
  top: 0px;
  bottom: 0px; 
  background: #0000008f;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  .msg-center {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    font-size: large;
  }
}
.warning_limits{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: green;
  .warning_red{
    color: red;
  }

}