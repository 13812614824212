@import '../../../theme.scss';

.order-detail{
    button{
        width:100%;
        height: auto;
        padding: 5px;
    }
    .p-datatable .p-datatable-tbody div {
        color : black;  
       
    }
    .align-center{
        display:flex;
        justify-content: center;
    }
    .inconsistency{
        &_open{
            background-color: $failureColor;
            font-weight: bold;
        }
        &_solved{
            background-color: $successColor;
            font-weight: bold;
        }
    }          
}

