.content-dialog {
  .footer-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .content-erro {
      width: 100%;
      text-align: left;
      color: red;
    }
  }
}
