@import '../../theme.scss';

.tag{
    display: inline-block;
    padding: 5px;
    padding-left: 10px;
    justify-content: space-around;
    border: 1px solid $primaryColorTheme;
    border-radius: 25px;
    width: auto;
    line-height: 1.5;
    margin: 2.5px;


    &__container{
        display: flex;
        align-items: center;
    }

    &__body{
        display: inline-block;
        color: $primaryColorTheme;
    }
    &__remove{
        display: flex;
        justify-items: center;
        margin-left: 5px;
        .pi-times{
            cursor: pointer;
        }
    }

    &:hover{
        color: #fff;
        background-color:  $primaryColorTheme;
        border-color: #fff;
        .tag__body,
        .tag__remove,
        .tag__remove, i{
           color: #fff;
        }
    }
}