.order-delivery-form {
  .panel-border {
    width: 100%;
    margin: 5px;
    border: 1px solid #ccc;
    padding: 5px;
  }
}
.delivery-toolbar {
  .p-toolbar-group-left {
    display: flex;
  }
}
