@import '../../../theme.scss';

.iconTemplate{
    display: flex;
    align-items: center;
    justify-content: center;
    color:$tableHeaderFontColor;
    & > span {
        text-align: center;
        &:nth-child(1){
            margin-right: 5px;
        }
    }
}