@import '../../theme.scss';

.lxTotalizer{
    font-weight: bold;
    & > *{
            margin : 5px;
    }
}

.vl {
    border-left: 1.5px solid $mediumGrey;
    height: 70%;
  }

.lxPositiveValue{
    color : $successColor;
}

.lxNegativeValue{
    color : $failureColor;
}