@import "../../theme.scss";

.input-select {
    display: flex!important;
    position: relative;
    flex-wrap: nowrap;
    align-items: flex-start;
    width:100%;
    padding-right: 10px;
    box-sizing: border-box;

    & >  input {
      box-sizing: border-box;
      position: relative;
      z-index: 1;
      padding-left: 7px;
      padding-right: 7px;
    border: 1px solid #a6a6a6;
    font-size: 14px;
    border-radius: 3px;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: border-color 0.2s, box-shadow 0.2s;
    height: 33px;
    
  }
  
 
      & > .p-dropdown{
        font-size: 14px;
        
        &:hover{
            border-color: #a6a6a6!important;
        }  
        .p-dropdown-panel{
            border: none;
        }
      }

  
}
