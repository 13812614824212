.filtro {
  .row-align-right {
    display: flex;
    align-items: flex-end;
    flex-wrap: nowrap;
    align-content: center;
  }
  .row-align-right-bottom{
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
