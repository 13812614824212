@import '../../../theme.scss';

.box-cubage{
    display: flex; 
    align-items: flex-end;
    justify-content: flex-start;
    
    .cubage-field{
        width: 90%;
    }
    .freight-calculationForm-btn-modal{}
}
