
.header-title{
    margin-top: 10px;
    margin-left: 15px;

    .lxSplitter{
        width: 99%;
    }
}
 
.trace-delivery-form{
    margin: auto;
    max-width: 600px;
}
.align-bottom{
    display: flex;
    justify-content: flex-end;
}
.align-center{
    display: flex;
    justify-content: center;
}
.result-trace-code{
    font-size: 26px;
}

.vertical-image{
    max-height:350px;
    max-width:70px;
    vertical-align:middle;
    cursor: pointer;
}

.horizontal-image {
    max-height:auto;
    max-width:100%;
    cursor: pointer;
}