@import '../../../theme.scss';


@media (max-height:730px){
    .order-page{
        width: calc(100vw - 21vw);
        // overflow: hidden;
    }
}



.order-page{

    .p-grid{
        margin-top:0px;
    }
    .form-field-container{
        margin-top:7px;
    }

    .selector-label{
        display: inline-flex;
        align-items: center;
        font-size: 12px;
    }

    .selector{
        font-size: 30px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .button-action{
        width: auto;
        display: inline-flex;
        padding: 10px;
        align-items: flex-start;
        color: $corPrimaria;
        line-height: 1.7;
        height: 35px;
        margin-left: 5px;
        i {
            font-size: 20px;
        }

        &:hover{
            background-color: $corPrimaria;
            color: #fff;
            i{
                color:#fff;
            }
        }
    }

    .dashboard-container{
        display: flex;
        height: 85px;

        .dashboard-panel{
            width:120px;
            margin:6px;
            cursor: pointer;
            flex-grow: 1;
            background-color: #f0f8fb;
            border-radius: 10px;
            
            &:hover{
                .dashboard-panel__title{
                    color: #fff;
                    background-color: $corPrimaria;
                    border-radius: 10px;
                }
            }

            &.selected {
                .dashboard-panel__title{
                    color: #fff;
                    background-color: $corPrimaria;
                    border-radius: 10px 10px 0px 0px;
                }
            }

            .dashboard-panel__title{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding:5px;
                color:$corPrimaria;
                font-size: 14px;
                height: 45px;
                
            }

            .dashboard-panel__value{
                display: flex;
                justify-content: center;
                align-items: center;
                color:$corPrimaria;
                font-size: 14px;
                font-weight: bold;
                height: 30px;
                               
            }
        }
    }
}