.content-top {
  text-align: right;
  padding: 5px;
}

.content-info-route {
  padding: 10px;
  background: #e3e3e3;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 11px;
}

.info-veiculo-carga {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  flex-wrap: wrap;
  justify-content: space-between;

  .card-info {
    padding: 3px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 136px;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    height: 60px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  .content-vehicle {
    cursor: pointer;
    :hover {
      border-color: rgb(35, 49, 114);
    }
  }
  .warning {
    color: red;
  }
  .content-btn-edit-route {
    display: flex;
    flex-direction: row;
    align-items: center;
    .btn {
      margin-right: 5px !important;
      width: 90px !important;
      cursor: pointer;
    }
  }
}

.route-content{
  .header-routes{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1px;
  }
  .btn-header-route{
    margin-left: 10px;
  }
}