.align-bottom-right {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end !important; 
  align-items: flex-end !important;
}

.panel-categoria{
  margin-top: 30px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 12px;
}
