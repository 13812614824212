@import '../../theme.scss';


.btn-import-file{
    margin-right: 0.25em;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: flex-start;
    position: relative;
    padding-left: 5px;
    color: $corPrimaria;
    line-height: 1.7;
    height: 35px; 

    label{
        padding-right: 5px;
        cursor: pointer;
    }
    input{
        position: absolute;
    }
    
    i {
        font-size: 20px;
    }

    &:hover{
        background-color: $corPrimaria;
        color: #fff;
        i{
            color:#fff;
        }
        label{
            color:#fff;
        }
    }
}