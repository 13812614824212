@import "../../theme.scss";

.lxCardContainer {
  position: relative;
  width: 350px;
  height: 300px;
  &.expanded {
    animation: grow 1s;
    animation-fill-mode: forwards;
  }
  .p-card-body {
    padding: 0;
  }
}

.lxCardChart {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  &.expanded {
    width: 100%;
    height: 100%;

    .lxCardChart__body {
      height: calc(60vh - 50px);
    }
  }

  &__header {
    display: flex;
    height: 50px;
    border-bottom: 1px solid #f1f1f1;

    .header__title {
      padding-left: 5px;
      display: flex;
      flex-grow: 1;
      align-items: center;
      font-size: 22px;
    }
    .header__actions.show {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 5px;
      button {
        border: none;
        background: none;
        padding: 0;
        width: 20px;
        height: 20px;
      }
    }
    .header__actions {
      display: none;
    }
  }
  &__body-header {
    display: flex;
    justify-content: center;
    height: 20px;
  }
  &__body-info {
    display: flex;
    justify-content: center;
    height: 20px;
    font-size: 10px;
  }
  &__body {
    display: flex;
    height: 180px;
    transition: all 0.6s ease-in-out 0.1s;

    .body__action-left {
      display: flex;
      width: 30px;
      align-items: center;
      padding-left: 5px;
      button {
        border: none;
        background: none;
        padding: 0;
        width: 20px;
        height: 20px;
      }
    }
    .body__content {
      display: flex;
      flex-grow: 1;
      width: 80%;
    }
    .body__action-right {
      display: flex;
      width: 30px;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5px;
      button {
        border: none;
        background: none;
        padding: 0;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__footer {
    &.hide {
      display: none;
    }
    display: flex;
    height: 30px;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;

    .footer__footer-actions.show {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 5px;
      button {
        border: none;
        background: none;
        padding: 0;
        width: 20px;
        height: 20px;
      }      
    }
    .footer__footer-actions {
      display: none;      
    }
  }
  &__footer-ghost {
    display: none;
    height: 0;
  }
  &__footer-ghost.show {
    display: flex;
    position: absolute;
    top: 300px;
    width: 100%;
    min-height: 200px;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  }
  .footer-ghost__content{
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
       width: 150%;
      div{
          width: 100%;
      }
  }
}

@keyframes grow {
  0% {
    position: absolute;
    left: 10%;
    top: 10%;
    z-index: 100;
  }
  10% {
    position: absolute;
    left: 30%;
    top: 30%;
    z-index: 100;
  }
  100% {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: calc(100% - 15px);
  }
}
