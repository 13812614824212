.lxInputCurrency{
    & span{
        width: 8%
    }
    & input{
        width: 100%
    }
    & i{
        padding-right: 15px !important;
    }
}