@import "../../../theme.scss";

.order-card-list {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;

  .btn-footer {
    margin: 10px auto;
    width: 100%;
  }
  .btn-footer .button-action {
    margin: 0 auto;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    background-color: #163965;
    border: 1px solid #163965;
    color: white;
    border-radius: 2px;
    padding: 5px;
  }

  .btn-footer .button-action i {
    color: white;
    display: inline-block;
    margin-right: 5px;
  }

  .button-action:hover {
    background-color: #fff;
    color: #163965;
    cursor: pointer;
  }

  .button-action:hover i {
    color: #163965;
  }
  .button-action:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.p-toolbar-group-left {
  .button-action {
    margin: 0 auto;
    justify-content: center;
    background-color: #163965;
    border: 1px solid #163965;
    color: white;
    border-radius: 2px;
    margin-left: 10px;
    padding: 5px;
  }

  .button-action i {
    color: white;
    display: inline-block;
    margin-right: 5px;
  }

  .button-action:hover {
    background-color: #fff;
    color: #163965;
    cursor: pointer;
  }

  .button-action:hover i {
    color: #163965;
  }
  .button-action:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .lognex-btn-danger {
    display: inline-flex;
    width: auto;
    height: 35px;
    margin-left: 10px;
    padding: 0.5rem 0.5rem;
  }
  .lognex-btn-danger i {
    margin-right: 5px;
  }
}

.order-card-list-container {
  height: 600px;
  width: 100%;
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media (max-height: 730px) {
  .order-card-list-container {
    height: 260px;
  }
}

.order-card {
  display: flex;
  width: 340px;
  height: 230px;
  box-sizing: border-box;
  cursor: pointer;

  * {
    // border:1px solid red;
  }

  .order-card__status {
    width: 15px;
    background-color: aquamarine;
    height: 100%;
    border-right: none;
    border-radius: 5px 0px 0px 5px;
    position: relative;
    z-index: 2;
  }

  .order-card__content {
    position: relative;
    background-color: #f0f8fb;
    border-radius: 0px 5px 5px 0px;
    border-left: none;
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);

    input[type="checkbox"] {
      font-size: 14px;
      position: absolute;
      right: 4px;
      top: 4px;
      width: 20px;
      height: 20px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  .order-number {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: $corPrimaria;
  }
  .order-card-label {
    text-align: center;
    font-size: 10px;
  }

  .order-client {
    text-align: center;
    font-size: 16px;
    color: $corPrimaria;
  }
  .order-date {
    margin-top: 2px;
    text-align: center;
    font-size: 12px;
    color: $corPrimaria;
  }
}

.shipment-card {
  display: flex;
  width: 340px;
  height: 268px;
  box-sizing: border-box;
  cursor: pointer;

  .shipment-card__status {
    width: 15px;
    background-color: aquamarine;
    height: 100%;
    border-right: none;
    border-radius: 5px 0px 0px 5px;
    position: relative;
    z-index: 2;
  }

  .shipment-card__content {
    position: relative;
    background-color: #f0f8fb;
    border-radius: 0px 5px 5px 0px;
    border-left: none;
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);

    input[type="checkbox"] {
      font-size: 14px;
      position: absolute;
      right: 4px;
      top: 4px;
      width: 20px;
      height: 20px;
    }

    .icons-pendencies {
      position: absolute;
      right: 30px;
      top: 4px;
      width: 60px;
      height: 20px;
      display: flex;
      justify-content: flex-end;
      .icons-danger {
        color: $failureColor;
      }
    }
  }

  .shipment-number {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: $corPrimaria;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .shipment-card-label {
    text-align: center;
    font-size: 10px;
  }

  .shipment-route {
    text-align: center;
    font-size: 12px;
    color: $corPrimaria;
  }
  .shipment-freight-forecast {
    text-align: center;
    font-size: 12px;
    color: $corPrimaria;
  }

  .card-panel-group {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .card-panel-group__item {
      flex-grow: 1;
      width: 25%;
      margin: 3px;
      .shipment-card-value {
        text-align: center;
        font-size: 12px;
        color: $corPrimaria;
      }
    }
    .card-panel-group__urgent {
      display: flex;
      width: 100%;

      .item {
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding: 3px;
        display: flex;
        margin-right: 5px;
      }
    }
  }
  .shipment-date {
    margin-top: 2px;
    text-align: center;
    font-size: 12px;
    color: $corPrimaria;
  }
}

.p-grid {
  .p-col-10 {
    height: 1px;
    margin: 0;
    padding: 0;
  }
}
