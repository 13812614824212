@import '../../../theme.scss';

.div-component{
        border: solid 1px black;
        border-radius: 5px;
        border-color: $fontColor;
        margin-top: 5px;
        &  button{
            justify-content: center;
            
        }
    }
