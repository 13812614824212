
.modal-calculate-cubage-btn-form-footer{
    display: flex;
    align-items: flex-end;
    justify-content: end;

    .btn-footer{
        margin-left: 10px;
    }

}

.fields-modal-calculate-cubage{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .btn-container{
        display: flex;
        justify-content: flex-start;
        .btn-fields{
            margin-left: 5px;
            margin-top: 10px;
        }
    }
}

.typeCalculateInvisible{
    width: 30%;
    & > :first-child { 
        display: none;
}
}