.custom-fields-dialog {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 250px;
  label { 
    margin-top: 12px;
  }
  .btn-edit-fields{
    margin-left: 6px;
  }
  .content-btn-edit{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
  }
}
