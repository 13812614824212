@import '../../theme.scss';

.lognex-sidemenu-wrapper{
    position: sticky;
    top: 53px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100vh - 60px);
}

.lognex-sidemenu{
    display: flex;
	flex-direction: column;
	list-style: none;
    font-size : 20px;
    position: sticky;
    & .lognex-subsidemenu{
        left: 100%;
        width: 100%;
        position: absolute;
        overflow: hidden;
        visibility: hidden;
    }
    

    & ul{
        list-style: none;
    }
        
    &__menuItemLabelParent{
        position: relative;
	    display: flex;
	    align-items: center;
	    background: #fff;
	    height: 60px;
	    color: #011f45;
	    font-family: 'Rajdhani', sans-serif;
	    //font-weight: bold;
	    cursor: pointer;
        width: 200px;
        max-width: 200px;
        min-width: 60px;
        transition: width 0.8s ease-in-out 0.3s, opacity 0.8s ease-in-out 0.3s;
       
        & > span {
            display: inline-block;
	        padding-left: 10px;
	        font-family: 'Rajdhani', sans-serif;
            font-weight: bold;  
        }
        &__text{
            display: inline-block;
            transition:  opacity 0.8s ease-in-out 0.3s;
            text-overflow: ellipsis;
            overflow : hidden;
        }
        
        &__icon{
            display: absolute !important;
            padding: 0 !important;
            height: 25px !important;
            width: 25px !important;
            margin-left: 15px;
            left: 0;
            
        }
        &:hover{
            color: $secondaryColorTheme;
            background-color: $primaryColorTheme; 
        }
        &:hover:before {
            background-color: $secondaryColorTheme;
        }
        
        &:before {
            content: " ";
            position: absolute;
            width: 5px;
            height: 60px;
            top: 0;
            margin-right: 10px;
        }
    }

    &__menuItemLabelChild{
        position: relative;
	    display: flex;
	    align-items: center;
	    justify-content : space-between;
        background: #fff;
        background-color: #fcfcfc; 
	    height: 40px;
	    color: #011f45;
	    font-family: 'Rajdhani', sans-serif;
        font-weight: bold;
        line-height: 0.9;
	    cursor: pointer;
	    width: 100%;
	    overflow: hidden;
        padding-left : 22px;  
        font-size : 18px;
        background-color: #E3F2FD;//$lightGreyColor;
        &__arrowIcon{
            display: flex !important;
	        padding: 0 !important;
	        height: 9px;
	        width: 9px;
	        overflow: hidden;
	        margin: 15px 22px 15px 18px;
            & > img{
                width: 100%;
	            height: 100%;
            }
        }
        &:hover{
            color: $secondaryColorTheme;
            background-color: $primaryColorTheme; 
        }
    } 
    .lognex-sidemenuChild{
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 100%;
        width: 100%;
        list-style: none;  
    }
    
    & .lognex-sidemenu__item{
        display: flex;
	    flex-direction: column;
	    position: relative;
	    border-bottom: 1px solid #f5f5f5;
        background: #fff;
        padding:  15px, 22px !important; 
        &:hover{
            & .lognex-subsidemenu{
                overflow: visible;
                visibility: visible;
            }
        }       
    }
}
.lognex-subsidemenu{
    //* For now the menu just opens through the hover, may in the future if 
    //* the menu has more than one level, the click will be necessary again.
    // &.active {
    //     overflow: visible;
    //     visibility: visible;
    // }
    & .lognex-sidemenu__item{
        width: 200px;
    }
}

.lxLogoShipper {
    & img{
        transition: height 0.8s ease-in-out 0.3s;
        width: 100%;
        margin-bottom: 30%;
    }
}
