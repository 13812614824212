.carrier-pendence{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.btn-copy-pendence{
  background-color: #fff !important;
  border: 1px solid #cbcbcb !important;
  color: white !important;
  border-radius: 2px !important;
  height: 20px;
  width: 20px;
  margin-left: 20px;
  cursor: pointer;
}