.lxdockerpanel {
  height: 400px;
  width: 100%;
  opacity: 1;
  transition: height 0.8s ease-in-out 0.3s, opacity 0.8s ease-in-out 0.3s;

  &.hiddendiv {
    width: 100%;
    height: 5px;
    opacity: 0.1;
  }
}
.invoiceTable-form {
  .p-calendar {
    .p-inputtext {
      width: 100%;
    }
    .p-datepicker {
      min-width: 254px !important;
    }
  }
}
