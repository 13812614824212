@import url('https://fonts.googleapis.com/css?family=Rajdhani:400,700');

$corPrimaria:#011F45;
$fontFamily : 'Rajdhani', sans-serif;
$fontColor : #8c8c8c;
$darkGrey : #8c8c8c;
$greyColorTheme: #edecf1;
$lightGreyColor: #f0f8fb;
$primaryColorTheme : #163965; 
$secondaryColorTheme : #FF3303;
$dashboardGrey : #CFCDCE;
$mediumGrey : #CFCDCE;
$lightBlue : #F0F8FB;
$disabledColor : #cccccc;
$successColor :  #458B00;//#7CCD7C;
$failureColor : #EE0000;
$warningColor : #DD9D14; 
$tableHeaderBackgroundColor : #f4f4f4;
$tableBodyFontColor : #333;
$tableHeaderFontColor : #333;