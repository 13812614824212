.audit-record-modal-info-doc{
    .title{
        font-weight: bold;
    }
    .text{
       font-size: large;
    //    border: 1px solid red; 
       margin: 10px;
    }

    .list_internal_doc{
        border: 1px solid gray;
        height: 160px;
        overflow: auto;
    }
}