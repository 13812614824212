.custom-date-field{
  display: flex;
  flex-direction: column;
  .date-picker{
    width: 100%;
  }
  .time-picker{
    width: 100%;
  }
  .custom-date-input{
    width: 100%;
    display: flex;
    flex-direction: row; 
  }
  .date-picker{
    width: 100%;
  }
  .btn-dt{
    background-color: #163965 !important;
    border: 1px solid #163965 !important;
    width: 2.357em;
  }
  .icon-dt{
    color: white;
  }
}
