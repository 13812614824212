@import '../../theme.scss';

.timeline__ballon-right{
    display: flex;
    flex-direction: row-reverse;
    height: 60px;
    margin-bottom: 60px;
    overflow: hidden;
    width: 100%;
    
    & > .timeline_ballon-spike-container{
      & > .timeline__ballon-spike{
        width: 0%; 
        height: 0%; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent; 
        border-right:10px solid #73c2fb; 
    }
  }
  & > .timeline__card{
    width: 47%;
    z-index: 1;
    height: fit-content;
    background-color: #73c2fb;
   
  }
  
}

.timeline__ballon-left{
    display: flex;
    flex-direction: row;
    height: 60px;
    margin-bottom: 60px;
    overflow: hidden;
    width: 100%;
  & > .timeline_ballon-spike-container{
    z-index: 0;
    width: 60px;
    height: 60px;
    & > .timeline__ballon-spike{
      width: 0; 
      height: 0; 
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent; 
      
      border-left:10px solid #73c2fb;
    }
  }  
  
  & > .timeline__card{
    width: 47%;
    z-index: 1;
    height: fit-content;
    background-color: #73c2fb;
    color : white;
  }
}