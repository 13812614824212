@import '../../theme.scss';

.page {
  box-sizing: border-box;
  width: 100%;
  font-family: 'Rajdhani', sans-serif;
  &__header {
    
    box-sizing: border-box;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50px;
    z-index: 800;
    padding: 0 20px;
    & h1{
      padding-left: 15px;
      color :$primaryColorTheme;
      font-family: $fontFamily;
    }
  }

  &__content {
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
  }
}


