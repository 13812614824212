
.input-with-search-plus{
    display: flex;
    justify-content: space-around;
    & > span.p-autocomplete{
        flex-grow: 1;
    }

    input{
        width: 100%;
    }
}
