.portal-form {
  padding: 10px;
  background-color: #f2f7f7;
  height: 100%;
  width: 100%;
  .container-interno { 
       margin-left: 10px;
       margin-bottom: 10px;
       padding-bottom: 10px;
       margin-right: 10px;
  }
  .backgroundColor {
    background-color: #ffffff;
  }
  .p-grid-custom {
    display: flex;
    margin-right: -0.5em;
    margin-left: -0.5em;
    margin-top: -0.5em;
    flex-wrap: wrap;
    flex-direction: row; 
  }
  .card-portal { 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px;
    justify-content: flex-start;
    text-align: center;
    width: 209px;
    


    h2 {
      font-weight: 600;
    }
    h5 {
      font-weight: 500;
    }
    .content-item{
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: smaller;
        h5{
            font-weight: 500;
        }
    }

    .card-circulo {
      border-radius: 50%;
      border: 1px solid #ececec;
      height: 120px;
      width: 120px;
      margin: 10px;
      box-shadow: 0px 0px 8px 5px #d6d9d7;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-size: 46px;
      cursor: pointer;
    }
    .card-circulo-green {
      box-shadow: 0px 0px 8px 5px #91cb9e;
    }
    .card-circulo-red {
        box-shadow: 0px 0px 8px 5px #ff5151;
      }
    .card-circulo-orange{
      box-shadow: 0px 0px 8px 5px #ff9d0b;
    }

    .card-circulo:hover .span-value {
      scale: 1.2;
      color: #ffa937; 
    }
  }
}
