.log-order-page{

        .page__header{
            .p-card-body{
                min-height: 150px;
            }
        }
    
    }

.p-calendar {
    .p-inputtext {
            width: 100%;
    }
    .p-datepicker {
            min-width: 300px !important;
    }
}