@import '../../theme.scss';

.lognex-simple-table{

    & table {
        width: 100%;
    }
    & thead {
        background-color:$tableHeaderBackgroundColor;
    }
    & tbody {
        & div {
            color : $tableBodyFontColor;    
        }
        color : $tableBodyFontColor;
    }
    & table, tr, td, th{
        border: 1px solid $mediumGrey ;
        border-collapse: collapse;
    }
    & td {
        padding: 5px;
    }
    & th {
        padding : 15px 5px;
        color: black;
        & div> {
            color: black;
        }
    }
    
}