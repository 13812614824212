@import '../../theme.scss';
.lx-dashboard__card-indicator{
    border: 1px solid $lightGreyColor;
    border-radius: 3px;
    width: 180px;
    height: 90px;
    background-color: $lightBlue;
    & > div {
        margin-left: 7px;
        max-height: 30px;
        padding: 5px;
    }
    
    &__title {
        color: $secondaryColorTheme;
        font-size: 10px;
    }
    
    &__main{
        min-height: 40px;
        display: flex;

   
    &-number {
        display: flex;
        align-items: center;
        font-size: 32px;
        min-height: 40px;
        max-width: 80%;
    }
    &-arrows {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding:8px 0px;
        max-width: 24px;
        min-height: 40px;
        & .pi-caret-up{
            &.active{
                color : green;
            }
           padding: 0px;
            
        }

        & .pi-caret-down.active{
            color: $secondaryColorTheme;
        }
    }   
    }
    
    &__info {
        font-size: 10px;
        color: $dashboardGrey;
    }
}