@import "../../../../theme.scss";

.auditrecord-dashboard {
  align-content: center;

  .customToolbar-totalizadores {
    display: block;
    width: 100%;

    .p-component {
      font-size: 12px;
    }
  }

  .grid-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    background-color: $lightBlue;
    font-weight: bold;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin: 10px;
    padding: 5px;
    font-family: $fontFamily;
    height: 80px;

    .title {
      margin-bottom: 5px;
      align-self: center;
      box-sizing: border-box;
      color: $secondaryColorTheme;
      font-size: 19px;
    }

    .content {
      box-sizing: border-box;
      margin: 0;
      padding: 0px 0px 0px 0px;
      margin-top: 5px;
      font-size: 32px;
      line-height: 0.9;
      color: #16396599;
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      padding: 15px 25px 0px 25px;
    }
  }
  .grid-box:hover {
    transform: scale(1.05);
  }

  .grid-box-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 80px !important;
    overflow: hidden;
    background-color: $primaryColorTheme;
    font-weight: bold;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 5px;
    font-family: $fontFamily;

    .title {
      margin-bottom: 5px;
      align-self: center;
      box-sizing: border-box;
      color: white;
      font-size: 19px;
    }
    .content {
      box-sizing: border-box;
      margin: 0;
      padding: 0px 0px 0px 0px;
      margin-top: 5px;
      font-size: 32px;
      line-height: 0.9;
      color: white;
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      padding: 15px 25px 0px 25px;
    }
  }
}
