.deliveries-title {
  color: grey;
  font-size: 20px;
  padding: 2px;
}
.deliveries {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 5px;
  .delivery {
    padding: 3px;
  }
}
