@import "../../theme.scss";

.lx-dashboard__card-access {
  border: 1px solid $lightGreyColor;
  border-radius: 3px;
  width: 25%;
  // height: 200px;
  padding: 5px;
  & .pi-star {
    color: $dashboardGrey;
    font-size: 25px;
    &.favorite {
      color: $secondaryColorTheme;
    }
  }
  &__title {
    color: $primaryColorTheme;
    font-size: 20px;
    min-height: 30px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__info {
    font-size: 14px;
    color: $dashboardGrey;
  }
  & div {
    margin-left: 7px;
    padding: 5px;
  }

  &:hover {
    border: 1px solid $secondaryColorTheme;

    & .pi-star {
      color: $secondaryColorTheme;
    }
    & .lx-dashboard__card-access__title {
      color: $secondaryColorTheme;
    }
    cursor: default;
  }
}
