.content-top {
  text-align: right;
  padding: 5px;
}
.align-bottom-btn{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
}
.btn-space{
  margin-left: 10px;
}