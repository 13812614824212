.content-dialog{
  max-height: 80vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden; 
}

.contador{
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // border: 1px solid black;
  margin: auto;
  

  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: large;
    color: blue;
    // border: 1px solid black;
  }

  p{
    text-align: center;
    font-size: large;
    font-weight: bold;
    color: blue;
  }

  .resultado{
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .cardTextInportMulti{
    text-align: center;
    width: 260px;
    margin: 5px 10px 20px 10px;
    border: 1px solid blue;
  
  }
  
  .totalInportMulti{     
    margin-top: 30px;
    height: 60px;
    // border: 1px solid black;
    text-align: center;
    font-weight: bolder;
    font-size: x-large;
    color: blue;
  }


  
  
}

.footer-container {
  display: flex;
  justify-content: space-between; 
}
.footer-readxls {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: larger;
}
.label-error {
  color: red;
  font-size: larger;
}
.label-success {
  color: rgb(57, 132, 194);
  font-size: larger;
}
.label-total{
  font-size: larger;
}
.label-total-error{
  color: red;
}