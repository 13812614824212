@import '../../../theme.scss';

.lx-dashboard{
    font-family: $fontFamily;
    font-weight: bold;
    padding : 30px 0px 0px 20px;
    width: 100%;

    & > div > div {
        margin : 5px;
    }

    &__panel{
        font-size: 10px;
        width: 100%;
        &-header{
            font-size: 16px;
            display: flex;
            align-items: center;
            height: 25px;
            width: 100%;
            border: 1px solid $lightGreyColor;
            border-radius: 3px;
            background-color: $lightBlue;
            color : $secondaryColorTheme;
           padding : 15px;
           padding-left: 30px;
        }
        &-cards{
           display: flex;
            width: 100%;
            position: relative;
           & >div {
               margin: 5px;
               margin-bottom: 14px;
           }
        }
    }

    .grid-box{
        .text-selector__text{
            width:180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
        }
    }
   
}





