@import '../../theme.scss';

.timeline__card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    height: auto;
    overflow: hidden;
    padding-left : 5px;
    & > *{
        float:left;
        padding:auto;
        margin:1px;
        text-align: left;
        color:white;
        font-weight: bold;
    }

    & > div{
        font-size: 14px;
        height: 50%;
        color:white;
    }

}



.btn_image{
    width: auto;
    height: auto;
    margin-bottom: 10px;
    margin-left: 10px;
    border: none;
    background: none;
    & i {
        color:white;
    }
}

.btn_image:hover{
    border: none;
    cursor: pointer;
}

