.tower_dashboard {
  .row-indicator {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  .btnAcoes {
    border: "none";
    padding: 3px;
    cursor: pointer;
    margin: auto;
    background-color: transparent;
  }

  .row-center {
    display: flex;
    justify-content: center;
  }
  .centerItem {
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-content: space-around;
  }
  .center-itens-row {
    text-align: center;
    color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
    align-content: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-around;
  }

  .statusSincTravelOK{  
    font-size: 11px;
  }
  .statusSincTravelFault{
    color: #ff0808;
    font-size: 11px;
  }
}
