@import '../../theme.scss';

.lognex-toolbar{
    background-color: $lightBlue;
    border: 1px solid $greyColorTheme;
    border-radius: 2px;
    height: 50px;
    padding: 7px;

    .downloadXLSXTemplateButton{    
        width: 35px;
        padding: 5px;
        height: 35px;
        background-color: #ffffff;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px !important;
    }
    .p-toolbar-group-left{
        display: flex;
        float: left;
    }

    .p-toolbar-group-right{
        display: flex;
        a:hover{
            border: 1px solid #163965;
        }        
        :hover{
            border: 1px solid white;
        }        

    }    
}
.lxUploadFileButton{
        opacity: 0;
        height: 100%;
        width: 100%;
        position: relative;
        left: 0px;
        top: -24px;

        //display: none;
}
