@import "../../../../theme.scss";

.grid-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 145px;
  overflow: hidden;
  background-color: $lightBlue;
  font-weight: bold;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin: 10px;
  padding: 5px;
  font-family: $fontFamily;

  .title {
    // padding-left: 25px;
    margin-bottom: 5px;
    align-self: center;
    box-sizing: border-box;
    color: $secondaryColorTheme;
    font-size: 19px;
  }

  .content {
    box-sizing: border-box;
    margin: 0;
    padding: 0px 0px 0px 0px;
    margin-top: 5px;
    font-size: 32px;
    line-height: 1;
    color: #16396599;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding: 15px 25px 0px 25px;
  }
}
.grid-box-selected {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 145px;
  overflow: hidden;
  background-color: $primaryColorTheme;
  font-weight: bold;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin: 10px;
  padding: 5px;
  font-family: $fontFamily;

  .title {
    // padding-left: 25px;
    margin-bottom: 5px;
    align-self: center;
    box-sizing: border-box;
    color: $lightBlue;
    font-size: 19px;
  }

  .content {
    box-sizing: border-box;
    margin: 0;
    padding: 0px 0px 0px 0px;
    margin-top: 5px;
    font-size: 32px;
    line-height: 1;
    color: $lightBlue;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding: 15px 25px 0px 25px;
  }
}
