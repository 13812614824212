@import '../../theme.scss';
.lxNeuralRoutes{
    overflow: hidden;
    width:100%;
    height: 300px;
}

@media (max-height: 720px) {
    .lxNeuralRoutes{
        overflow: hidden;
        width:100%;
        height: 130px;
    }
  }
.lxImageCanvas {
    width: 100%;
    overflow: hidden;
}