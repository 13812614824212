.freightComponent-form{
    .field-label{
        font-size: 16px;
        color: ccc;
        font-weight: bold;
        line-height: 1.5;
        border-bottom: 2px solid #f1f1f1;
        padding-bottom: 5px;
        margin-bottom: 10px;
    
    }
    .lxGroupTypeLabel{
        padding-top: 10px;
        background-color: #f0f8fb;
        border-radius: 5px;
        padding-left: 10px;
        padding-bottom: 5px;
        min-height: 30px;
    }
}