.loader{
    width:50px;
    height: 50px;
    background-color: #fff;
    overflow: hidden;

    img{
        width: 100%;
        height: 100%;
    }
}