.let-component{
    height: 100%;
    width: 100%;
}
.form-field-container-right-center{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    background: #f3f3f3;
    padding: 3px;
    margin-bottom: 4px;
    cursor: pointer;
}
.form-field-container-right-center:hover{
    background: #ffffff;
}