.importfile-form{
  & .p-dropdown{
    width: 240px !important;
  }
  & .lxSelectFile{
    display: flex;
    flex-direction: row;
  }
}

//* CSS created because the span was overling the button and difficulting the click
//* That's kind of a gambiarra, but work our problem out for the moment
.lxUploadFile{
  .p-clickable{
    z-index: -1;
  }
  .p-button.p-fileupload-choose{
    font-size: bold !important;
    &:before { 
      font-size: 20px;
      font-weight: bold;
      font-size: bold !important;
      color : white;
      content: "+";
    }
  }
}




/*.p-fileupload-buttonbar{
    & > button{
      background-color: $primaryColorTheme !important;
    }
  }*/