.lxImputTravelObsDialog {
  .lxInputTextArea {
    width: 100%;
    border: solid 1px #ccc;
    padding: 5px;
  }
  .lxInputTextArea:focus {    
    border: solid 1px rgb(167, 167, 167);
    outline: none;
  }
  .p-dialog-content {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8; 
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    padding-top: 0px !important;
}
}
