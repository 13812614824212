// @import '~leaflet/dist/leaflet.css'; // sass
// @import 'react-leaflet-markercluster/dist/styles.min.css'; // sass
.mapa-route {
  .leaflet-container {
    width: 100%;
    height: 600px;
  }

  .marker-cluster-custom {
    background-color: rgba(95, 177, 202, 0.507);
    border-radius: 50%;
    padding: 5px;
    display: flex;
  }
  .span-child-count {
    margin: auto;
    color: rgb(19, 0, 71);
  }

  .span-child-count-warning {
    margin: auto;
    color: red;
  }

  .leaflet-routing-alternatives-container {
    display: none;
  }
  .marker-cluster-route {
    z-index: 0;
    border-radius: 50%;
    background-color: rgba(77, 207, 60, 0.562);
    border: 3px solid rgba(211, 69, 69, 0.678);
  }

  .marker-path-taken {
    background-color: rgba(230, 36, 204, 0.418);
    z-index: 3;
  }
  .marker-cluster-route-dest {
    z-index: 0;
    border-radius: 50%;
    background-color: rgba(36, 0, 240, 0.562);
    border: 3px solid rgba(98, 129, 197, 0.678);
  }

 
  .content-delivery-box {
    display: flex;
    flex-direction: column;
    .btn-click-edit-delivery {
      width: auto;
      background-color: #dddddd;
      padding-right: 10px;
      padding-left: 10px;
      margin-top: 5px;
      cursor: pointer;
    }

  }
  .line_dot_polyline {
    stroke: rgb(0, 0, 0);
    fill: none;
    stroke-dasharray: 5, 10;
    stroke-width: 2;
  }
}
