.calculated-component{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .btn-actions{
        // border: 1px solid red;
        margin-left: 8px;
        margin-right: 8px;
    }
}