 
.btn-add {
  cursor: pointer;
}
.btn-del {
   cursor: pointer;
}
.table-custom-fields {
  width: 100%;
}
.table-custom-fields-th {
  padding: 4px;
  background: #ccc;
}

.table-custom-fields-td {
  padding: 3px;
  background: rgb(238, 238, 238);
  text-align: center;
}
