@import '../../theme.scss';

.login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 5px auto;
    font-family: $fontFamily;
    font-weight: bold;
    .loader{
        position: absolute;
    }
    
    & > button {
        font-family: $fontFamily;
        font-weight: bold;
        font-size: 20px;
        color: var(--corTemaTerciaria);
        border-radius: 3px;
        border: 1px solid $greyColorTheme;
        background-color: $greyColorTheme;
        margin : 7px auto;
        padding: 3px;
        height: 60px;
        width: 100%;
        &:hover{
            font-family: $fontFamily;
            font-weight: bold;
            background-color: white;
            color: $primaryColorTheme;
        }
        
    }
    &__logo-container{
        text-align: center;
        & > img {
            max-width:300px;
            width: auto;
            height: auto;
            margin : 30px auto;
        }
    }
    & .btn-destacado{
        border: 1px solid #011F45;
        background-color: #011F45;
        color: white;
        padding: 3px;
        width: 100%;
        &:hover{
            background-color: white;
            color: $primaryColorTheme;
        }
    }

    & input{
        margin : 7px auto;
        font-family : 'Rajdhani', sans-serif;
        font-weight: bold;
        font-size: 20px;
        height: 60px;
        width: 100%;
        margin-top : 5px;
        padding-right: 22px;
        padding-left: 22px;
        border : solid 1px $greyColorTheme;
        color: $fontColor;
        font-family: $fontFamily;
        font-weight: bold;
        border-radius: 2px;
        &::placeholder{
            color: $fontColor;;
        }
        &[type="checkbox"]{
            height: 22px;
            width: 22px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0 auto;
            background-clip: content-box;
            border: 1px solid $greyColorTheme;
            outline:0;
            padding: 4px;
            border-radius: 5px;
            margin-right: 5px;
            &:checked {
                background-color: red;
            }
        }
    }
    &__lembrar{
        margin: 20px 0px;
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        &__span-lembrar{
            justify-content: space-around;
            display: flex;
        }
        &__span-esqueceuSenha{
            color: $secondaryColorTheme;
            justify-content: space-around;
            display: flex;
            text-decoration: underline;
        }
    }
}


