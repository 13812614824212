
.align-inline-center{
  display: flex;
  align-items: center;
  width: 176px;
  padding-right: 17px;
  padding-bottom: 10px;
  justify-content: flex-end;
  flex-direction: row;
  label{
    width: max-content;
  }
}
.space-10px{
  width: 10px;
}

.inline-center-vertical{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.align-bottom-left{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}