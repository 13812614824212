@import '../../../theme.scss';

.error-list {
  max-height: 200px;
  width: calc(100% - 7px);
  overflow: auto;
  padding-left: 25px;
  li {
    font-size: 14px;
    line-height: 1.3;
  }
}
.import-result {
  &__title {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.textArea {
  width: 650px;
  height: 108px;
}

.button-clipboard-div {
  display: flex;
  flex-direction: row-reverse;
}

.button-clipboard {
  background-color: white;
  border: 1px solid $primaryColorTheme;
  color: $primaryColorTheme;
  & i {
    color: $primaryColorTheme;
  }
  &:hover {
    background-color: $primaryColorTheme;
    border-color: white;
    color: white;
    & i {
      color: white;
    }
    background-color: $primaryColorTheme;
  }
  &:disabled {
    opacity: 0.5;
  }
}
